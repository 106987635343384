.VisuallyHidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  bottom: 0;
  padding: 0;
  clip: rect(0, 0, 0, 0);
  overflow: hidden;
}

.Container {
  width: 100%;
  max-width: 1280px;
  padding-left: 40px;
  padding-right: 40px;
  @include xs {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.LoaderContent {
  display: flex;
  justify-content: center;
  padding: 8px;
}

.Grey {
  color: $grey;
}

.LightSuccess {
  color: $success-light;
}

.FadeInMedium {
  animation: fadeIn $medium $ease-in-out both;
}

.FadeInLongestShort {
  animation: fadeIn $longest $ease-in-out $short both;
}

.FadeInBottomLongest {
  animation: fadeInBottom $longest $ease-in-out both;
}

.FadeInRightLongest {
  //animation: fadeInRight $longest $ease-in-out both;
}

.SearchForm {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
