@import '../../styles/variables';
@import '../../styles/mixins';

.Header {
  box-shadow: 0px 4px 32px 0px rgba(0, 0, 0, 0.12) !important;

  &-Container {
    margin: auto;
    display: flex;
    align-items: center;
    height: 79px;
  }

  &-Content {
    position: absolute;
    right: 15px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    button {
      height: 58px;
      padding: 8px;
      background-color: $light-block-dark;
      border-radius: 12px;
      box-shadow: none;
      color: black;

      &.MuiButton-contained:hover{
        background-color: $light-block-dark !important;
      }
    }
  }

  &-Logo {
    display: block;
    background: transparent;
    border: none;
    padding: 48px 0 10px 48px;
    cursor: pointer;
    &:focus {
      outline: none;
    }
  }

  &-VGRussia {
    display: block;
    width: 212px;
    height: 32px;
    background-image: url("../../assets/images/VGRUSSIA.svg");
    @include xs {
      width: 160px;
      height: 24px;
      background-size: contain;
    }
  }

  &-Image {
    width: 210px;
    height: auto;
    @include xs {
      width: 190px;
    }
  }

  &-User {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 8px 16px;
    padding-bottom: 8px;
    border-bottom: 1px solid $light-grey;
    color: black;
    cursor: pointer;
  }

  &-UserContent {
    display: flex;
  }
  &-UserAvatar {
    background-image: url("../../assets/images/UserAvatar.svg");
    background-size: cover;
    width: 46px;
    height: 46px;
    border: 0;
    border-radius: 14px;
    @include xs {
      background-image: url("../../assets/images/UserAvatar-Mobile.svg");
    }
  }
  &-UserInfo {
    margin: 0 12px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    font-size: 16px;
    line-height: 18px;
  }

  &-UserEmail {
    color: $font-dark-grey;
    font-size: 14px;
  }

  &-UserName {
    margin-left: 8px;
    @include xs {
      display: none;
    }
  }
}

.HeaderMobile {
  box-shadow: none !important;
  display: block !important;

  &-Container {
    margin: auto;
    display: flex;
    align-items: center;
    height: 66px;
    box-shadow: none !important;
    background-color: $primary !important;
  }

  &-Content {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .MuiPaper-root{
      .MenuDropdown {
        position: absolute;
        top: 124px !important;
        right: 0 !important;
        left: 10% !important;
        width: 100%;
        height: 100%;
        box-shadow: none !important;
      }
    }
  }

  &-Menu {
    background-image: url("../../assets/images/menu.png");
    width: 32px;
    height: 32px;
    border: 0;
    border-radius: 8px;
  }

  &-UserWrapper {
    display: flex;
    align-items: center;
    padding: 17px 17px;
    color: black;
    background-color: $light-block-dark;
    .Header-UserAvatar {
      @include xs {
        width: 46px;
        height: 46px;
        background-size: cover;
      }
    }
  }

  &-UserLinkWrapper {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    height: 100%;
    padding: 24px 17px;
    a {
      color: #000;
      text-decoration: none;
      line-height: 1.75;
      font-size: 16px;
    }
  }
}





