@import '../../styles/variables';
@import '../../styles/mixins';

.Footer {
  text-align: center;
  width: 100%;
  padding: 48px;
  @include xs {
    position: absolute;
    bottom: 0;
    padding: 24px 17px;
  }

  &-Terms {
    font-size: 12px;
    text-align: left;
  }

  &-Link {
    display: inline-block;
    color: $font-primary-dark;
    text-decoration: none;
    font-size: 16px;
    line-height: 30px;
    &:not(:last-child) {
      margin-bottom: 4px;
    }
    @include xs {
      color: $font-grey;
      text-decoration: none;
    }
  }
}
