@import '../../styles/variables';

.Loader {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(white, 0.3);
  z-index: 10000;
  &_block {
    position: absolute;
    top: 0;
    left: 0;
    right: auto;
    bottom: auto;
    z-index: 2;
  }
}

.LoaderEnter {
  opacity: 0;
}

.LoaderEnterActive {
  opacity: 1;
  transition: opacity $shortest $ease-in-out;
}

.LoaderExit {
  opacity: 1;
}

.LoaderExitActive {
  opacity: 0;
  transition: opacity $shortest $ease-in-out;
}
