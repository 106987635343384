@import '../../styles/variables';
@import '../../styles/mixins';

.Link {
  &-Title {
    font-size: 40px;
    font-weight: 700;
    color: $font-black;
    @include xs {
      margin-bottom: 14px;
      font-size: 24px;
      font-weight: 500;
    }
  }

  &-Disabled {
    height: 100%;
    margin-top: 16px;
    padding: 16px 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    background-color: $light-block;
    @include xs {
      padding: 0;
      margin: 0;
      flex-direction: row;
      gap: 1rem;
    }
  }

  &-ImgBlock {
    height: 70px;
    max-width: 230px;
    display: flex;
    align-items: center;
    justify-content: center;

    &_tip {
      margin-bottom: 6px;
    }

    &_disabled {
      margin-bottom: 12px;
    }

    @include xs {
      width: 80px;
      height: 60px;
      margin-bottom: 0;
    }

    &-Wrapper {
      position: absolute;
      top: 17px;
      right: 17px;
      @include xs {
        position: static;
        height: 100%;
        width: 41px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 14px;
        background-color: #F9FAFB;
      }
    }
  }

  &-Img {
    max-width: 100%;
    max-height: 100%;

    .System-ImgBlock_disabled > & {
      opacity: 0.3;
    }
  }

  &-Tip {
    font: inherit;
    color: black;
  }

  &-Description {
    color: $font-dark-grey;
  }

  &-Img {
    &-Vaillant {
      background-image: url("../../assets/images/Vaillant.svg");
      width: 143px;
      height: 38px;
      @include xs {
        background-image: url("../../assets/images/Vaillant-Mobile.svg");
        background-size: cover;
        width: 39px;
        height: 55px;
      }
    }

    &-VK {
      background-image: url("../../assets/images/VK.svg");
      width: 48px;
      height: 48px;
    }

    &-VKVideo {
      background-image: url("../../assets/images/VKVideo.svg");
      width: 58px;
      height: 52px;
    }

    &-Protherm {
      background-image: url("../../assets/images/Protherm.svg");
      width: 147px;
      height: 33px;
      @include xs {
        background-image: url("../../assets/images/Protherm-Mobile.svg");
        background-size: cover;
        width: 48px;
        height: 48px;
      }
    }

    &-VKProtherm {
      background-image: url("../../assets/images/VKProtherm.svg");
      width: 48px;
      height: 48px;
    }

    &-VKVideoProtherm {
      background-image: url("../../assets/images/VKVideoProtherm.svg");
      width: 58px;
      height: 52px;
    }

    &-Telegram {
      background-image: url("../../assets/images/Telegram.svg");
      width: 150px;
      height: 37px;
      @include xs {
        background-image: url("../../assets/images/Telegram-Mobile.svg");
        background-size: cover;
        width: 48px;
        height: 48px;
      }
    }
  }
}