/* same as THEME variables */
$primary-light: rgba(38, 154, 140, 0.15);
$primary: #269A8C;
$primary-dark: #00897E;
$secondary: #fff;
$secondary-dark: #eee;
$block: rgba(0, 0, 0, 0.3);
$block-dark: rgba(0, 0, 0, 0.4);
$block-darker: rgba(0, 0, 0, 0.5);
$light-block: rgba(255, 255, 255, 0.6);
$light-block-dark: #F6F6F6;
$grey: rgba(0, 0, 0, 0.54);
$light-grey: rgba(0, 0, 0, 0.05);

$font-black: #000;
$font-grey: rgba(0, 0, 0, 0.7);
$font-light-grey: rgba(0, 0, 0, 0.15);
$font-dark-grey: rgba(0, 0, 0, 0.5);

$font-primary: #fff;
$font-primary-light: rgba(255, 255, 255, 0.5);
$font-primary-dark: rgba(255, 255, 255, 0.7);

$border-light: rgba(255, 255, 255, 0.3);

$success: #43a047;
$success-light: #62eb66;
$error: #d32f2f;

$font-family: Interstate, sans-serif;
$fs-sm: 12px;
$fs-st: 16px;
$fs-md: 20px;
$fs-lg: 28px;
$fs-xl: 32px;
$fs-xxl: 36px;

$shortest: 150ms;
$shorter: 200ms;
$short: 250ms;
$standard: 300ms;
$medium: 500ms;
$long: 800ms;
$longer: 1000ms;
$longest: 1200ms;
$ease-in-out: cubic-bezier(0.4, 0, 0.2, 1);
$ease-out: cubic-bezier(0, 0, 0.2, 1);

/* CSS only variables */
$max-width: 1280px;
$main-padding: 40px;
$text-shadow: 1px 2px 3px rgba(0, 0, 0, 0.2);
$shadow: 0 1px 3px 0 rgba(black, 0.2), 0 1px 1px 0 rgba(black, 0.14), 0 2px 1px -1px rgba(black, 0.12);
$xs: 599px;
$sm: 959px;
$md: 1280px;
