body {
  background: center / cover no-repeat;
  background-attachment: fixed;
  min-width: 320px;
  overflow-y: scroll;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: auto;
}

img {
  display: block;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-weight: normal;
}

ul,
p,
figure {
  margin: 0;
}

a {
  user-select: none;
  text-decoration: none;
  cursor: pointer;
}

ul,
nav {
  padding: 0;
  list-style: none;
}

fieldset {
  border: 1px solid $block;
  margin: 0;
  padding: 16px;
}

legend {
  padding: 0 5px;
  font-weight: normal;
  color: $grey;
  font-size: 16px;
}
