@import '../../styles/variables';
@import '../../styles/mixins';

.Rate {
  margin: 16px 0;
  text-align: right;
  padding: 0 48px;
  min-width: 341px;
  @include xs {
    height: 51px;
    padding: 0;
    margin: 0;
    background-color: $primary-light;
    width: 100%;
  }

  &-Block {
    text-align: left;
    display: inline-flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 25px;
    overflow: hidden;
    border: 2px solid $border-light;
    border-radius: 24px;
    width: 100%;

    @include xs {
      height: 51px;
      border: 0;
      padding: 0;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
  }

  &-Text {
    color: $font-primary-light;
    margin-bottom: 4px;
    padding-top: 6px;
    @include xs {
      display: none;
    }
  }

  &-Currency {
    display: flex;
    align-items: center;
    font-size: 36px;
    line-height: 24px;
    @include xs {
      color: $primary;
      font-size: 24px;
    }
  }

  &-Help {
    display: none;
    @include xs {
      display: block;
      width: 52px;
      height: 78px;
      margin-left: -18px;
      background-image: url("../../assets/images/help-icon.svg");
      background-size: cover;
    }
  }

  &-Dialog {
    .MuiDialog-paper {
      border-radius: 25px;
      box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.08)
    }
    &-Text {
      padding: 51px;
      text-align: center;
      color: $font-black;
    }
    &-Currency {
      padding: 14px;
      font-size: 36px;
      line-height: 24px;
    }
  }
}
