@import '../../styles/variables';
@import '../../styles/mixins';

.Main {
  position: relative;
  display: flex;

  &-Mobile {
    flex-direction: column;
  }


  &-Title {
    font-weight: 300;
    font-size: $fs-xxl;
    color: $secondary;
    margin-bottom: 80px;
    text-transform: uppercase;
    @include sm {
      font-size: $fs-xl;
    }
    @include xs {
      font-size: $fs-lg;
      margin-bottom: 64px;
    }
  }

  .Main-TitleContent {
    &_mobile {
      display: none;
    }
    @include ex {
      display: none;
      &_mobile {
        display: block;
      }
    }
  }

  &-TitleText {
    display: inline-block;
    line-height: 1;
    padding: 6px 10px 4px 10px;
    background-color: $primary;
    @include sm {
      line-height: 1.05;
      padding: 5px 8px 3px 8px;
    }
  }

  &-Sections {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1rem;
    margin: 48px;
    height: calc(100% - 220px);
    @include xs {
      margin: 24px 16px;
    }
  }

  &-Navigation {
    position: relative;
    width: 100%;

    .MuiTab-wrapper {
      padding: 0 36px;
      font-size: 25px;

      @include xs {
        padding: 0;
        font-size: 16px;
        font-weight: 400;
      }
    }

  }

  &-Sidebar {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 35%;
    min-width: 310px;

    background-color: #00897e;

    &-Content {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
    }
  }

  &-Content {
    background-color: #FFF;
    min-height: 100vh;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex: 1;
  }
}


.MainMobile {
  &-Sidebar {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    height: 100%;
    background-color: #fff;
    padding: 24px 5px;

    .MuiTab-wrapper {
      color: $font-black !important;
    }

    .Mui-selected {
      background-color: #fff !important;
      .MuiTab-wrapper {
        color: $primary !important;
      }
    }
  }
}

.Main-Section-Footer {
  color: $font-light-grey;
  font-size: 18px;
  @include xs {
    text-align: center;
    padding-top: 22px;
  }
}