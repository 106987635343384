@import '../../styles/variables';

.ExtendedTable {
  &-Wrapper {
    overflow-x: auto;
  }

  &-Link {
    font-family: $font-family;
    padding: 5px;
    font-weight: normal;
    font-size: 14px;
    transition: color $short $ease-in-out;
    border: none;
    cursor: pointer;
    background-color: transparent;
    outline: none;
    display: flex;
    align-items: center;
    &_cell {
      color: $primary;
      font-size: 13px;
      font-weight: 600;
      padding: 5px 0;
    }
    &_large {
      font-size: 16px;
    }
    &:hover,
    &_active {
      color: $primary-dark;
    }
  }
}
