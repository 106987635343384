@mixin retinaImg($path, $file) {
  background-image: url('#{$path}.#{$file}');
  @media (min-resolution: 2dppx), (-webkit-min-device-pixel-ratio: 2) {
    background-image: url('#{$path}@2x.#{$file}');
  }
}

@mixin pseudo($pos: absolute, $display: block, $content: '') {
  content: $content;
  display: $display;
  position: $pos;
}

@mixin xs {
  @media (max-width: 599px) {
    @content;
  }
}

@mixin ex {
  @media (max-width: 649px) {
    @content;
  }
}

@mixin sm {
  @media (max-width: 959px) {
    @content;
  }
}

@mixin md {
  @media (max-width: 1199px) {
    @content;
  }
}
