@import '../../styles/variables';
@import '../../styles/mixins';

.SystemsSection {
  &:not(:last-child) {
    margin-bottom: 40px;
  }

  &-Title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $secondary;
    letter-spacing: 1px;
    font-size: $fs-md;
    margin-bottom: 16px;
  }

  &-TitleText {
    display: flex;
    align-items: center;
    color: $font-black;
    font-size: 24px;
    @include xs {
      font-size: 18px;
      font-weight: 400;
      color: $font-dark-grey;
    }
  }

  &-TitleLabel {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    background-color: $light-block;
    color: black;
    box-shadow: $shadow;
  }

  &-Block {
    display: flex;
    flex-direction: row;
    @include xs {
      flex-direction: column;
      align-items: center;
    }
    .MuiPaper-elevation1 {
      @include xs {
        width: calc(100vw - 24px);
        height: 100px;
        padding: 10px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
      }
      &:has(a) {
        padding: 0;
        @include xs {
          padding: 10px;
        }
      }
      a {
        height: 100%;
        padding-top: 30px;
        justify-content: space-around;
        @include xs {
          padding: 0;
          flex-direction: row;
          justify-content: flex-start;
          gap: 1rem;
          border-radius: 0 !important;
          box-shadow: none !important;
        }
      }
    }
  }
}
