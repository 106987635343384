@import '../../styles/variables';
@import '../../styles/mixins';

.System {
  &-Title {
    font-size: 40px;
    font-weight: 700;
    color: $font-black;
    @include xs {
      margin-bottom: 14px;
      font-size: 24px;
      font-weight: 500;
    }
  }
  &-Disabled {
    height: 100%;
    margin-top: 16px;
    padding: 16px 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    background-color: $light-block;
    @include xs {
      padding: 0;
      margin: 0;
      flex-direction: row;
      gap: 1rem;
    }
  }

  &-ImgBlock {
    height: 70px;
    max-width: 230px;
    display: flex;
    align-items: center;
    justify-content: center;
    &_tip {
      margin-bottom: 6px;
    }
    &_disabled {
      margin-bottom: 12px;
    }
    @include xs {
      width: 80px;
      height: 60px;
      margin-bottom: 0;
    }

    &-Wrapper {
      position: absolute;
      top: 17px;
      right: 17px;
      @include xs {
        position: static;
        height: 100%;
        width: 41px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 14px;
        background-color: #F9FAFB;
      }
    }
  }

  &-Img {
    max-width: 100%;
    max-height: 100%;
    .System-ImgBlock_disabled > & {
      opacity: 0.3;
    }
  }

  &-Tip {
    font: inherit;
    color: black;
  }

  &-Description {
    color: $font-dark-grey;
  }
}
