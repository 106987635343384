@import '../../styles/variables';

.Request {
  color: black;

  &-Fieldset {
    margin-bottom: 8px;
  }

  &-User {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
  }

  &-List {
    width: 100%;
    list-style-type: disc;
    list-style-position: inside;
  }

  &-Label {
    text-align: right;
    display: inline-block;
    width: 60px;
    margin-right: 8px;
    line-height: 1.7;
    color: $grey;
  }
}
